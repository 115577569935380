import Me from './Me'

const About = () => {
    return (
        <div className="relative overflow-hidden py-36">
            <Me/>
            <h1 className="absolute bottom-0 -left-48 text-60xl  opacity-5 leading-extra-none bgText">ABOUT</h1>
        </div>
    )
}

export default About
